<template>
	<div>
		<div>
			<h3 class="mb-7">Постановка на очередь детей дошкольного возраста (до 6 лет) для направления в детские дошкольные организации</h3>
		</div>
		<v-row>
			<v-col lg="4" md="5" cols="12">
				<div>
					<template v-if="Object.keys(requestInfo).length != 0">
						<template v-if="requestInfo.values.request_status.value == 1">
							<v-btn block color="warning" class="mb-4" @click.stop="notificationDialog = true">
								Уведомить о поступлении заявки
							</v-btn>
							<v-dialog v-model="notificationDialog" width="500" :persistent="actionIsLoading">
								<v-card>
									<v-card-title class="headline lighten-2">Уведомить о поступлении заявки</v-card-title>
									<v-card-text>
										<v-form class="form" ref="notificationForm">
											<v-file-input
												color="indigo lighten-1"
												label="Выберите ЭЦП"
												accept=".p12"
												:rules="[v => !!v || 'Eds is required']"
												@change="toBase64"
												required >
											</v-file-input>
											<v-text-field
												color="indigo lighten-1"
												label="Пароль"
												type="password"
												prepend-icon="mdi-key"
												v-model="ecpPassword"
												:rules="[v => !!v || 'Password is required']"
												required
											></v-text-field>
										</v-form>
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="notificationSubmit" :loading="actionIsLoading">
											Подписать
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</template>
						<template v-if="requestInfo.values.request_status.value == 2">
							<v-btn block color="success" class="mb-4" @click.stop="acceptDialog = true">
								Одобрить
							</v-btn>
							<v-dialog v-model="acceptDialog" width="500" :persistent="actionIsLoading">
								<v-card>
									<v-card-title class="headline lighten-2">Одобрить заявку</v-card-title>
									<v-card-text>
										<v-form class="form" ref="acceptForm">
											<v-text-field v-if="Object.keys(requestPrivilegeInfo).length != 0 && requestPrivilegeInfo.values.privilege_available.value != 0 && requestPrivilegeInfo.values.privilege_type.key == '005'"
												v-model="maxAge"
												label="Максимальный возраст нахождения в ДДО"
												type="number"
												:rules="[v => !!v || 'Required!']"
												required
											></v-text-field>
											<v-file-input
												color="indigo lighten-1"
												label="Выберите ЭЦП"
												accept=".p12"
												@change="toBase64"
												:rules="[v => !!v || 'Eds is required']"
												required >
											</v-file-input>
											<v-text-field
												color="indigo lighten-1"
												label="Пароль"
												type="password"
												prepend-icon="mdi-key"
												v-model="ecpPassword"
												:rules="[v => !!v || 'Password is required']"
												required
											></v-text-field>
										</v-form>
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="acceptSubmit" :loading="actionIsLoading">
											Подписать
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
							<v-btn block color="error" class="mb-4" @click.stop="getRejectReasonList">
								Отказать
							</v-btn>
							<v-dialog v-model="rejectDialog" width="500" :persistent="actionIsLoading">
								<v-card>
									<v-card-title class="headline lighten-2">Отказать</v-card-title>
									<v-card-text>
										<v-form class="form" ref="rejectForm">
											<v-select
												v-model="rejectReasonSelected"
												:items="rejectReasonList"
												item-text="name"
												item-value="id"
												label="Причина отказа"
												return-object>
												<template v-slot:item="{ item }">
													<div style="max-width: 600px;" class="py-3">
														{{item.name}}
													</div>
												</template>
											</v-select>
											<template v-if="rejectReasonSelected.id == '001' || rejectReasonSelected.id == '002'">
												<v-textarea
													v-model="rejectReasonTextRu"
													label="Уточнение причины отказа на русском языке"
													rows="2" filled auto-grow
												></v-textarea>
												<v-textarea
													v-model="rejectReasonTextKk"
													label="Уточнение причины отказа на казахском языке"
													value=""
													rows="2" filled auto-grow
												></v-textarea>
											</template>
											<template v-if="rejectReasonSelected.id == '007'">
												<v-textarea
													v-model="rejectReasonTextRu"
													label="Перечень отсутствующих документов на русском языке"
													value=""
													rows="2" filled auto-grow
												></v-textarea>
												<v-textarea
													v-model="rejectReasonTextKk"
													label="Перечень отсутствующих документов на казахском языке"
													value=""
													rows="2" filled auto-grow
												></v-textarea>
											</template>
											<v-file-input
												color="indigo lighten-1"
												label="Выберите ЭЦП"
												accept=".p12"
												:rules="[v => !!v || 'Eds is required']"
												@change="toBase64">
											</v-file-input>
											<v-text-field
												color="indigo lighten-1"
												label="Пароль"
												type="password"
												prepend-icon="mdi-key"
												:rules="[v => !!v || 'Password is required']"
												v-model="ecpPassword">
											</v-text-field>
										</v-form>
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="rejectSubmit" :loading="actionIsLoading">
											Подписать
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</template>
						<v-divider class="my-6" />
					</template>
				</div>
				<v-card elevation="2" class="mb-5">
					<v-card-title class="card-title-custom">{{requestInfo.name}}</v-card-title>
					<v-card-text class="request-data card-text-custom pt-4">
						<v-row v-for="(item, itemKey) in requestInfo.values" :key="itemKey">
							<v-col>{{item.name}}:</v-col>
							<v-col>{{item.value}}</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card elevation="2" class="mb-5">
					<v-card-title class="card-title-custom">{{requestPrivilegeInfo.name}}</v-card-title>
					<v-card-text class="request-data card-text-custom pt-4">
						<v-row v-for="(item, itemKey) in requestPrivilegeInfo.values" :key="itemKey">
							<v-col>{{item.name}}:</v-col>
							<v-col v-if="item.type != 'file'">{{item.value}}</v-col>
							<v-col v-else><a :href="item.value" :download="item.fileName">{{item.fileName}}</a></v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<v-card elevation="2" v-if="Object.keys(requestDecisionInfo).length != 0" class="mb-5">
					<v-alert outlined :color="(requestDecisionInfo.values.resolution.value ? 'green' : 'red')+' darken-2'" class="pa-0">
						<v-card-title class="card-title-custom">{{requestDecisionInfo.name}}</v-card-title>
						<v-card-text class="request-data card-text-custom pt-4">
							<v-row v-for="(item, itemKey) in requestDecisionInfo.values" :key="itemKey">
								<v-col>{{item.name}}:</v-col>
								<v-col>{{item.value}}</v-col>
							</v-row>
						</v-card-text>
					</v-alert>
				</v-card>
				<v-expansion-panels multiple v-model="panel" class="request-data">
					<v-expansion-panel v-for="(panel, panelKey) in requestDate" :key="panelKey">
						<v-expansion-panel-header>{{panel.name}} </v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="pt-4">
								<v-row v-for="(item, itemKey) in panel.values" :key="itemKey">
									<v-col class="prop-title">{{item.name}}</v-col>
									<v-col v-if="item.type != 'file'">{{item.value}}</v-col>
									<v-col v-else><a :href="item.value" :download="item.fileName">{{item.fileName}}</a></v-col>
								</v-row>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</div>
</template>


<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], // TODO
			notificationDialog: false,
			acceptDialog: false,
			rejectDialog: false,
			requestDate: {},
			requestInfo: {},
			requestPrivilegeInfo: [],
			requestDecisionInfo: {},
			requestId: null,
			maxAge: null,
			ecpFileData: '',
			ecpPassword: '',
			rejectReasonSelected: {},
			rejectReasonList: [],
			rejectReasonTextKk: '',
			rejectReasonTextRu: '',
			actionIsLoading: false,
		}),
		methods: {
			async getData() {
				await this.$http.get('/request/'+this.$route.params.id).then((response) => {
					this.requestDate = response.data.data.block;
					this.requestInfo = response.data.data.info;
					this.requestPrivilegeInfo = response.data.data.privilege;
					if(response.data.data.decision) {
						this.requestDecisionInfo = response.data.data.decision;
					}
					this.requestId = this.requestInfo.values.requestId.value;
				}).catch(() => {});
			},
			async notificationSubmit() {
				if(this.$refs.notificationForm.validate()) {
					this.actionIsLoading = true;
					await this.$http.post('/request/resolution/notification/'+this.requestId, {
						"p12": this.ecpFileData,
						"password": this.ecpPassword
					}).then((response) => {
						if(response.data.status == 'success') {
							this.getData().then(() => {
								this.notificationDialog = false;
								this.$store.dispatch('alertAdd', {text: "Заявка успешно обработана.", type: 'success'});
							});
						} else {
							console.log(response.data.message);
						}
					}).catch(() => {});
					this.actionIsLoading = false;
				}
			},
			async acceptSubmit() {
				if(this.$refs.acceptForm.validate()) {
					this.actionIsLoading = true;
					await this.$http.post('/request/resolution/positive/'+this.requestId, {
						"maxAge": this.maxAge,
						"p12": this.ecpFileData,
						"password": this.ecpPassword
					}).then((response) => {
						if(response.data.status == 'success') {
							this.getData().then(() => {
								this.acceptDialog = false;
								this.$store.dispatch('alertAdd', {text: "Заявка успешно обработана.", type: 'success'});
								this.$store.dispatch('getUnresolvedRequestCout');
							});
						} else {
							console.log(response.data.message);
						}
					}).catch(() => {});
					this.actionIsLoading = false;
				}
			},
			async getRejectReasonList() {
				if(!this.rejectReasonList.length) {
					await this.$http.get('/handbook/refuse').then((response) => {
						if(response.data.status == 'success') {
							this.rejectReasonList = response.data.data;
						}
					}).catch(() => {});
				}
				this.rejectDialog = true;
			}, 
			async rejectSubmit() {
				if(this.$refs.rejectForm.validate()) {
					this.actionIsLoading = true;
					await this.$http.post('/request/resolution/negative/'+this.requestId, {
						"p12": this.ecpFileData,
						"password": this.ecpPassword,
						"reason": this.rejectReasonSelected.id,
						"reason_text_ru": this.rejectReasonTextRu,
						"reason_text_kk": this.rejectReasonTextKk
					}).then((response) => {
						if(response.data.status == 'success') {
							this.getData().then(() => {
								this.rejectDialog = false;
								this.$store.dispatch('alertAdd', {text: "Заявка успешно обработана.", type: 'success'});
								this.$store.dispatch('getUnresolvedRequestCout');
							});
						} else {
							console.log(response.data.message);
						}
					}).catch(() => {});
					this.actionIsLoading = false;
				}
			},
			toBase64(file) {
				if(file) {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
						if ((encoded.length % 4) > 0) {
							encoded += '='.repeat(4 - (encoded.length % 4));
						}
						this.ecpFileData = encoded;
					};
				}
			}
		},
		watch: {
			acceptDialog: function (v) {
				if(!v) {
					this.maxAge = null;
				}
			},
			rejectDialog: function (v) {
				if(!v) {
					this.rejectReasonSelected = {};
					this.rejectReasonTextRu = '';
					this.rejectReasonTextKk = '';
				}
			},

		}
	}
</script>

<style scoped>
	.card-title-custom {
		font-size: 15px;
		background-color: #f5f5f5;
		line-height: 1;
	}
	.card-text-custom {
		font-size: 15px;
		color: #000 !important;
	}
	.request-data .v-expansion-panel-header {
		background-color: #f5f5f5;
		min-height: initial;
		font-weight: 500;
	}
	.request-data .row + .row {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}
	.prop-title {
		font-size: 15px;
	}
</style>